import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import images from "../images/images";
import "./uploadFileComponent.scss";
import BackNextActionButtonComponent from "../backNextActionButtonComponent";
import Aries from "../../locale/en-Us.json";
import { apiBaseUrl } from "../api/api";
import ComingSoon from "../model/comingSoon";
import ButtonGroupFooter from "../buttonGroupFooter";

const fileTypesDefault = ["docx", "pdf", "xlsx", "xls"];

const UploadFilesComponentV2 = ({
  label,
  handleUploadfile,
  nextStep,
  backStep,
  showBack,
  showNext,
  api_url,
  disable_box,
  subtitleFileType,
  inProgress,
  handleDisableNextButton = () => {},
  fileTypes,
  acceptedFileTypeText = Aries.GLOBAL_UPLOAD_FILE_FILETYPES,
  passFinancialYear = false,
  financialYear = "",
}) => {
  const [file, setFile] = useState(null);
  const storedUserData = JSON.parse(localStorage.getItem("userData"));
  const [errorMessage, setErroMessage] = useState("");
  const [message, setMessage] = useState("");
  const [processDone, setProcessDone] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const [disableNextButton, setDisableNextButton] = useState(true);

  const handleChange = (files) => {
    setFile(files);
    // setDisableNextButton(true);
  };
  const handleClose = () => {
    setShowModel(false);
  };

  const saveFile = async () => {
    const formData = new FormData();
    formData.append("file", file);

    if (passFinancialYear) {
      formData.append("financialYear", financialYear)
    }

    try {
      const response = await fetch(apiBaseUrl + api_url, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${storedUserData.token}`,
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        setErroMessage("Please check file :" + errorData.message);
        setTimeout(() => {
          setErroMessage("");
        }, 3000);
      } else {
        const responseData = await response.json();
        setProcessDone(true);
        setTimeout(() => {
          setMessage("");
          handleUploadfile(responseData);
          setDisableNextButton(false);
        }, 1000);
      }
    } catch (error) {
      console.error("Error in file upload:", error.message);
    }
  };

  const buttonGroup = {
    left: [],
    right: [
      {
        text: Aries.BUTTON_GLOBAL_BACK,
        className: "back-button",
        fn: backStep,
        imageLink: images["arrow-left.svg"],
      },
      {
        text: Aries.BUTTON_GLOBAL_NEXT,
        className: "primary-button",
        fn: () => {
          nextStep();
        },
        disabled: disableNextButton,
        rightImageLink: images["arrow-right.svg"],
      },
    ],
  };

  return (
    <>
      <div className="file-upload-wrapper">
        <div className="wrapper-label">
          {showBack && (
            <img
              className="user-icon"
              src={images["aistra-vaa-avatar-1.svg"]}
              loading="lazy"
              alt="User icon"
            />
          )}

          <div
            className="label"
            dangerouslySetInnerHTML={{ __html: label }}
          ></div>
        </div>
        <div
          className="file-upload-container"
          onClick={() => setShowModel(disable_box ? true : false)}
        >
          <div
            className={
              disable_box
                ? "file-upload-box disbale-uploader"
                : "file-upload-box"
            }
          >
            {file && file ? (
              <>
                <div className="files-selected">
                  <div>{file?.name}</div>
                  <img
                    className="cross-icon"
                    src={images["red-cross.svg"]}
                    loading="lazy"
                    alt="cross button"
                    onClick={() => {
                      setFile(null);
                      setProcessDone(false);
                      setDisableNextButton(false);
                    }}
                  />
                </div>
                {errorMessage && <p className="error">{errorMessage}</p>}
                {message && <p className="success">{message}</p>}
              </>
            ) : (
              <FileUploader
                types={fileTypes ? fileTypes : fileTypesDefault}
                handleChange={(e) => handleChange(e)}
                name="file"
              >
                <div className="file-wrapper">
                  <img
                    className="file-icon"
                    src={images["upload-file.svg"]}
                    loading="lazy"
                    alt="upload icon"
                  />
                </div>
                <div
                  className="info"
                  dangerouslySetInnerHTML={{
                    __html: Aries.GLOBAL_UPLOAD_FILE_HEADER,
                  }}
                ></div>
                <div className="accepted-files">{acceptedFileTypeText}</div>
              </FileUploader>
            )}
            {processDone ? (
              <p>File Upload Successfully</p>
            ) : (
              <button
                className="back-button button"
                disabled={!file}
                onClick={(e) => saveFile(e)}
              >
                {Aries.GLOBAL_UPLOAD_FILE_BUTTON}
              </button>
            )}
          </div>
        </div>
        <ButtonGroupFooter
          buttonGroupObject={buttonGroup}
          className={"fixed-button-group"}
        />
      </div>
      <ComingSoon showModel={showModel} handleClose={handleClose} />
    </>
  );
};
export default UploadFilesComponentV2;
